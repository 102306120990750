import { Button, Card, Flex, Form, Input, Typography } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Api } from "../../services/Api";
import Logo from "../../assets/logo.svg";

type FieldType = {
  name?: string;
  email?: string;
  password?: string;
};

function Register() {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    // @ts-ignore
    mutationFn: async (data: any) => {
      await Api.post(`/register`, {
        name: data.name,
        email: data.email,
        password: data.password,
      });
      try {
        // @ts-ignore
        dataLayer.push({ event: "user-registered-for-trial" });
      } catch (error) {
        console.error(error);
      }
      navigate(`/forms/new`, {
        replace: true,
      });
    },
  });

  return (
    <>
      <Flex
        align="center"
        justify="center"
        style={{
          background: "#f0f2f5",
          minHeight: "100vh",
        }}
      >
        <Flex
          style={{
            flexDirection: "column",
            gap: 24,
            width: "100%",
            maxWidth: 480,
          }}
          align="start"
        >
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <img src={Logo} alt="" style={{ maxHeight: 42 }} />
          </div>
          <Card title="Criar conta" bordered={false} style={{ width: "100%" }}>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ name: "", address: "" }}
              onFinish={(values) => {
                mutate(values);
              }}
              onFinishFailed={() => {}}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label="Nome"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Informe o seu nome.",
                  },
                ]}
              >
                <Input size="large" autoFocus />
              </Form.Item>

              <Form.Item<FieldType>
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Informe o seu e-mail.",
                  },
                ]}
              >
                <Input type="email" size="large" />
              </Form.Item>

              <Form.Item<FieldType>
                label="Senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Informe uma senha",
                  },
                ]}
              >
                <Input type="password" size="large" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    width: "100%",
                  }}
                  size="large"
                >
                  Continuar para a criação do formulário
                </Button>
              </Form.Item>
            </Form>

            <Flex justify="center">
              <Typography.Paragraph type="secondary">
                Já possui uma conta? <Link to={"/login"}>Entrar</Link>
              </Typography.Paragraph>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </>
  );
}

export default Register;
