import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Api } from "../../services/Api";
import { InputText } from "./InputText";
import "./style.css";
import { InputLongText } from "./InputLongText";
import { InputPhone } from "./InputPhone";
import { Divider, Space, Typography } from "antd";
import { InputRadio } from "./InputRadio";
import { InputCheckbox } from "./InputCheckbox";

function AnswerForm() {
  const { formId } = useParams();
  const methods = useForm<any>();
  const { handleSubmit } = methods;
  const [answered, setAnswered] = useState<boolean>(false);

  const { data, isLoading } = useQuery(`form-model:${formId}`, async () => {
    const { data } = await Api.get(`/form-models/${formId}`);
    return data;
  });

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation<any>({
    mutationFn: async (data) => {
      console.log(data);
      await Api.post("/submit-answer", {
        formId,
        answers: data,
      });
      setAnswered(true);
    },
  });

  if (isLoading)
    return (
      <div className="answer-form">
        <span>Carregando...</span>
      </div>
    );

  if (answered) {
    return (
      <div className="answer-form">
        <div className="content">
          <h2 className="text-center">Enviado com sucesso!</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="answer-form">
      <div className="content">
        <h1>{data.title}</h1>

        <FormProvider {...methods}>
          <form action="" onSubmit={handleSubmit(onSubmit as any)}>
            {data.questions.map((q: any) => (
              <div className="card mb-3" key={q.id}>
                <h2 className="m-0 mb-3">{q.title}</h2>

                {q.type === "simple-text" && (
                  <InputText name={q.id} required={q.required} />
                )}
                {q.type === "name-complete" && (
                  <InputText name={q.id} required={q.required} />
                )}
                {q.type === "long-text" && (
                  <InputLongText name={q.id} required={q.required} />
                )}
                {q.type === "email" && (
                  <InputText name={q.id} type="email" required={q.required} />
                )}
                {q.type === "phone" && (
                  <InputPhone name={q.id} required={q.required} />
                )}
                {q.type === "select-one" && (
                  <InputRadio
                    name={q.id}
                    required={q.required}
                    options={q.options}
                  />
                )}
                {q.type === "select-multiple" && (
                  <InputCheckbox
                    name={q.id}
                    required={q.required}
                    options={q.options}
                  />
                )}
              </div>
            ))}

            <Space style={{ gap: "1rem" }}>
              <button type="submit" className="btn" disabled={isSubmitting}>
                Enviar
              </button>
              <Typography.Text type="secondary">
                Ao enviar, você concorda com os <a href="#1">termos de uso</a>.
              </Typography.Text>
            </Space>
          </form>
        </FormProvider>

        <Divider />

        <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
          Formulário criado por Privacy21, utilizando a plataforma Item 1.
        </Typography.Paragraph>
      </div>
    </div>
  );
}

export default AnswerForm;
