import { WhatsAppOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Flex,
  Skeleton,
  Space,
  Statistic,
  Typography,
} from "antd";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Api } from "../../services/Api";

function ShowAnswer() {
  const { answerId, id } = useParams();

  const { data: answer, isLoading } = useQuery(
    `answer:${answerId}`,
    async () => {
      const { data } = await Api.get(`/answers/${answerId}`);
      return data;
    }
  );

  const { data: form } = useQuery(`forms:${id}`, async () => {
    const { data } = await Api.get(`/forms/${id}`);
    return data;
  });

  return (
    <>
      <Flex align="center" justify="space-between">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="../">Formulários</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`../${id}`}>{form?.title || "Formulário"}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Resposta</Breadcrumb.Item>
        </Breadcrumb>
      </Flex>

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
          marginBottom: "1rem",
        }}
      >
        <Skeleton loading={isLoading}>
          {answer && (
            <Statistic
              title="Enviado em"
              value={new Date(answer.createdAt).toLocaleString()}
            />
          )}
        </Skeleton>
      </div>

      <Typography.Title level={3}>Respostas</Typography.Title>

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        <Skeleton loading={isLoading}>
          {!isLoading && (
            <>
              {answer.questions.map((q: any, index: number) => (
                <div>
                  <h3>{q.title}</h3>
                  {q.type === "phone" && (
                    <Space>
                      <a
                        href={`tel:${q.answer}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatPhoneNumberIntl(q.answer)}
                      </a>
                      <Button
                        icon={<WhatsAppOutlined />}
                        href={`https://wa.me/${q.answer}`}
                        target="_blank"
                      >
                        Abrir no WhatsApp
                      </Button>
                    </Space>
                  )}
                  {q.type === "email" && (
                    <a
                      href={`mailto:${q.answer}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {q.answer}
                    </a>
                  )}
                  {!["email", "phone"].includes(q.type) && <p>{q.answer}</p>}
                  {index !== answer.questions.length - 1 && <Divider />}
                </div>
              ))}
            </>
          )}
        </Skeleton>
      </div>
    </>
  );
}

export default ShowAnswer;
