import {
  CheckOutlined,
  DownloadOutlined,
  LinkOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Flex,
  Row,
  Skeleton,
  Statistic,
  Table,
  TableProps,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Api } from "../../services/Api";

export function formatDuration(
  durationInMilliseconds: number | null | undefined
) {
  if (!durationInMilliseconds) return null;
  const differenceInSeconds = Math.floor(durationInMilliseconds / 1000);
  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} ${
      differenceInSeconds > 1 ? "segundos" : "segundo"
    }`;
  }
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${
      differenceInMinutes > 1 ? "minutos" : "minuto"
    }`;
  }
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 60) {
    return `${differenceInHours} ${differenceInHours > 1 ? "horas" : "hora"}`;
  }
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 60) {
    return `${differenceInDays} ${differenceInDays > 1 ? "dias" : "dia"}`;
  }
  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 60) {
    return `${differenceInMonths} ${differenceInMonths > 1 ? "meses" : "mês"}`;
  }
  const differenceInYears = Math.floor(differenceInMonths / 12);
  if (differenceInYears < 60) {
    return `${differenceInYears} ${differenceInYears > 1 ? "anos" : "ano"}`;
  }
}

const columns: TableProps<any>["columns"] = [
  {
    title: "Enviada em",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value, row) => {
      return <Link to={`answer/${row._id}`}>{value}</Link>;
    },
  },
  {
    title: "Campo obrigatório",
    dataIndex: "requiredField",
    key: "requiredField",
  },
];

function ShowForm() {
  const { id } = useParams<{ id: string }>();
  const [linkCopied, setLinkCopied] = useState<boolean>();

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    }
  }, [linkCopied]);

  const { data: answers, isLoading: isLoadingAnswers } = useQuery(
    `answers:${id}`,
    async () => {
      const { data } = await Api.get(`/answers`, {
        params: { formId: id },
      });
      return data.map((r: any) => ({
        ...r,
        createdAt: new Date(r.createdAt).toLocaleString(),
      }));
    }
  );

  const { data } = useQuery(`form:${id}`, async () => {
    const { data } = await Api.get(`/forms/${id}`);
    return data;
  });

  return (
    <>
      <Flex align="center" justify="space-between">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="../">Formulários</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data?.title || "Formulário"}</Breadcrumb.Item>
        </Breadcrumb>
      </Flex>

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        <Row>
          <Col span={6}>
            <Statistic title="Respostas coletadas" value={3} />
          </Col>
          <Col span={6}>
            <Statistic
              title="Status"
              value={"Publicado"}
              valueStyle={{ color: "#3f8600" }}
              prefix={<CheckOutlined />}
            />
            <Button
              icon={<PauseOutlined />}
              style={{ marginTop: "1rem" }}
              disabled
            >
              Pausar
            </Button>
          </Col>
          <Col span={12}>
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              Coletar respostas
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              O link para responder ao formulário é público, e pode ser enviado
              para qualquer pessoa.
            </Typography.Paragraph>
            <Button
              icon={linkCopied ? <CheckOutlined /> : <LinkOutlined />}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                  `${
                    window.location.protocol + "//" + window.location.host
                  }/f/${id}`
                );
                setLinkCopied(true);
              }}
            >
              {linkCopied
                ? "Link copiado com sucesso"
                : "Copiar link para responder"}
            </Button>
          </Col>
        </Row>
      </div>

      <Tabs
        tabBarExtraContent={
          <Button icon={<DownloadOutlined />} disabled>
            Baixar todas as respostas como CSV
          </Button>
        }
        style={{
          marginTop: "1rem",
        }}
        activeKey="answers"
        items={[
          {
            key: "answers",
            label: "Respostas",
          },
        ]}
        onChange={() => {}}
      />

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        <Skeleton loading={isLoadingAnswers}>
          <Table columns={columns} dataSource={answers} />
        </Skeleton>
      </div>
    </>
  );
}

export default ShowForm;
