import { FC } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  name: string;
  required?: boolean;
  options: string[];
}

export const InputRadio: FC<Props> = ({ name, required, options }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {options.map((option) => (
        <div
          className="form-radio"
          style={{ display: "block", marginBottom: ".6rem" }}
        >
          <input
            type={"radio"}
            value={option}
            id={`${name}-${option}`}
            style={{ marginRight: ".6rem" }}
            {...register(name, {
              validate: (value) => {
                if (required && !value) {
                  return "Por favor, selecione uma opção.";
                }
              },
            })}
          />
          <label htmlFor={`${name}-${option}`}>{option}</label>
        </div>
      ))}
      {errors?.[name] && (
        <span className="field-error-message">
          {(errors[name]?.message as any) || "Inválido"}
        </span>
      )}
    </>
  );
};
