import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.css";

interface Props {
  name: string;
  required?: boolean;
}

export const InputPhone: FC<Props> = ({ name, required }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <PhoneInput
              placeholder="Digite o número de telefone"
              value={value}
              onChange={onChange}
              className="form-control"
              defaultCountry="BR"
            />
          );
        }}
        rules={{
          validate: (value) => {
            if (required) {
              const parsedValue = value?.trim();
              if (!parsedValue) return "Por favor, preencha este campo.";
            }
          },
        }}
      />

      {errors?.[name] && (
        <span className="field-error-message">
          {(errors[name]?.message as any) || "Inválido"}
        </span>
      )}
    </>
  );
};
