import { PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Flex,
  Skeleton,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useQuery } from "react-query";
import MediaQuery from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../services/Api";
import { getColorForUptime } from "../../utils/getColorForUptime";

function dateToAge(date: string) {
  const now = new Date();
  const parsed = new Date(date);
  const differenceInMinutes = Math.floor(
    (now.getTime() - parsed.getTime()) / 60000
  );
  if (differenceInMinutes < 1) {
    return "agora mesmo";
  }
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${
      differenceInMinutes > 1 ? "minutos" : "minuto"
    }`;
  }
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 60) {
    return `${differenceInHours} ${differenceInHours > 1 ? "horas" : "hora"}`;
  }
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 60) {
    return `${differenceInDays} ${differenceInDays > 1 ? "dias" : "dia"}`;
  }
  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 60) {
    return `${differenceInMonths} ${differenceInMonths > 1 ? "meses" : "mês"}`;
  }
  const differenceInYears = Math.floor(differenceInMonths / 12);
  if (differenceInYears < 60) {
    return `${differenceInYears} ${differenceInYears > 1 ? "anos" : "ano"}`;
  }
}

function ListForms() {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery("forms", async () => {
    const response = await Api.get("/forms");
    return response.data.map((item: any) => ({
      ...item,
      key: item.id,

      age: dateToAge(item.createdAt),
    }));
  });

  const columns: TableProps<any>["columns"] = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (text, row) => <Link to={`${row._id}`}>{text}</Link>,
    },
    {
      title: "Criado há",
      dataIndex: "age",
      key: "age",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "Destination",
      dataIndex: "address",
      key: "address",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, row) => {
        return null;
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Formulários</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          minHeight: 280,
          borderRadius: 8,
          overflow: "auto",
        }}
      >
        <Flex justify="end" align="center" style={{ marginBottom: "1rem" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("new");
            }}
          >
            Criar um novo formulário
          </Button>
        </Flex>

        <MediaQuery maxWidth={600}>
          <Skeleton loading={isLoading}>
            {data?.map((m: any) => (
              <Card
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  navigate(`/monitors/${m.id}`);
                }}
              >
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    marginBottom: ".6rem",
                  }}
                >
                  <Typography.Text>{m.name}</Typography.Text>
                  {m.up ? (
                    <Tag color="green">On</Tag>
                  ) : (
                    <Tag color="red">Off</Tag>
                  )}
                </Flex>

                {m.last24HoursUptime && (
                  <Space size={2}>
                    {!!m.last24HoursUptime &&
                      Array.isArray(m.last24HoursUptime) &&
                      m.last24HoursUptime.map((item: any) => (
                        <div
                          key={item.startDate}
                          style={{
                            width: 8,
                            height: 24,
                            background: getColorForUptime(
                              item.uptimePercentage
                            ),
                            borderRadius: 8,
                          }}
                        ></div>
                      ))}
                  </Space>
                )}
              </Card>
            ))}
          </Skeleton>
        </MediaQuery>

        <MediaQuery minWidth={601}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
          />
        </MediaQuery>
      </div>
    </>
  );
}

export default ListForms;
