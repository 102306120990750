import {
  DeleteOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Skeleton,
  Switch,
  Typography,
  message,
} from "antd";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { Api } from "../../services/Api";
import { getErrorMessage } from "../../utils/getErrorMessage";

interface Question {
  id: string;
  type:
    | "simple-text"
    | "name"
    | "email"
    | "phone"
    | "long-text"
    | "name-complete"
    | "select-one"
    | "select-multiple";
  title: string;
}

function questionTypeToText(type: Question["type"]) {
  switch (type) {
    case "simple-text":
      return "Texto curto";
    case "long-text":
      return "Texto longo";
    case "name-complete":
      return "Nome completo";
    case "email":
      return "E-mail";
    case "phone":
      return "Telefone";
    case "select-one":
      return "Selecionar um";
    case "select-multiple":
      return "Selecionar múltiplos";
    default:
      return "Desconhecido";
  }
}

interface Props {
  id?: string;
}

export const MonitorForm: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate, isLoading } = useMutation({
    // @ts-ignore
    mutationFn: async (data: any) => {
      let fields: any = {
        title: "",
        questions: [],
      };
      fields["title"] = data.title;
      for (const question of questions) {
        fields["questions"].push({
          id: question.id,
          title: data[`question-${question.id}-title`],
          type: question.type,
          required: data[`question-${question.id}-required`] || false,
          options: data[`question-${question.id}-options`] || undefined,
        });
      }
      console.log(fields);
      const {
        data: { id },
      } = await Api.post(`/questions`, fields);
      console.log(id);
      navigate(`/forms`, {
        replace: true,
      });
    },
    onError: (error) => {
      messageApi.open({
        type: "error",
        content: getErrorMessage(error),
      });
    },
  });

  useEffect(() => {
    if (!id) return setIsLoadingForm(false);
    Api.get(`/monitors/${id}`).then(({ data }) => {
      setInitialValues(data);
      setIsLoadingForm(false);
    });
  }, [id]);
  const [questions, setQuestions] = useState<Question[]>([]);

  if (isLoadingForm) return <Skeleton />;

  return (
    <Form
      name="basic"
      style={{ maxWidth: 600 }}
      initialValues={initialValues}
      onFinish={(values) => {
        mutate(values);
      }}
      autoComplete="off"
      layout="vertical"
    >
      {contextHolder}

      <Form.Item
        label="Título do formulário"
        name="title"
        rules={[
          {
            required: true,
            message: "Por favor, informe um nome para o formulário.",
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Divider />

      {questions.map((q, index) => (
        <div>
          <Card
            size="small"
            title={
              <span>
                Pergunta {index + 1}{" "}
                <Typography.Text
                  type="secondary"
                  style={{
                    fontWeight: "normal",
                    marginLeft: ".6rem",
                    fontSize: ".8rem",
                  }}
                >
                  {questionTypeToText(q.type)}
                </Typography.Text>
              </span>
            }
            style={{
              marginBottom: "1rem",
            }}
            extra={
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  setQuestions((old) =>
                    old.filter((question) => question.id !== q.id)
                  );
                }}
              >
                Excluir
              </Button>
            }
          >
            <Form.Item
              name={`question-${q.id}-title`}
              rules={[
                {
                  required: true,
                  message: "Por favor, informe um título para a questão.",
                },
              ]}
            >
              <Input placeholder="Título da pergunta" />
            </Form.Item>

            <Form.Item name={`question-${q.id}-required`} label="Obrigatório">
              <Switch />
            </Form.Item>

            {["select-one", "select-multiple"].includes(q.type) && (
              <>
                <Form.List
                  name={`question-${q.id}-options`}
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 2) {
                          return Promise.reject(
                            new Error("At least 2 passengers")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? "Opções" : ""}
                          required={true}
                          key={field.key}
                          style={{ marginBottom: ".6rem" }}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Por favor, informe um valor para a opção.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="Informe o valor da opção"
                              style={{ width: "60%" }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{ marginLeft: "1rem" }}
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "60%" }}
                          icon={<PlusOutlined />}
                        >
                          Adicionar opção
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
          </Card>
        </div>
      ))}

      {!questions.length && (
        <Empty description="Adicione ao menos uma pergunta ao formulário clicando no botão abaixo." />
      )}

      <Dropdown
        menu={{
          items: [
            {
              key: "3",
              label: "Texto curto",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "simple-text",
                  },
                ]);
              },
            },
            {
              key: "long-text",
              label: "Texto longo",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "long-text",
                  },
                ]);
              },
            },
            {
              type: "divider",
            },
            {
              key: "name-complete",
              label: "Nome completo",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "name-complete",
                  },
                ]);
              },
            },
            {
              key: "email",
              label: "E-mail",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "email",
                  },
                ]);
              },
            },
            {
              key: "phone",
              label: "Telefone",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "phone",
                  },
                ]);
              },
            },
            {
              type: "divider",
            },
            {
              key: "select-one",
              label: "Selecionar um",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "select-one",
                  },
                ]);
              },
            },
            {
              key: "select-multiple",
              label: "Selecionar múltiplos",
              onClick: (e) => {
                setQuestions((old) => [
                  ...old,
                  {
                    id: v4(),
                    title: "Question title",
                    type: "select-multiple",
                  },
                ]);
              },
            },
          ],
        }}
      >
        <Button style={{ marginTop: "1rem" }} icon={<DownOutlined />}>
          Adicionar pergunta
        </Button>
      </Dropdown>

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar e continuar
        </Button>
      </Form.Item>
    </Form>
  );
};
