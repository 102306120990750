import { FC } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  name: string;
  required?: boolean;
}

export const InputLongText: FC<Props> = ({ name, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <textarea
        rows={3}
        className="form-control"
        {...register(name, {
          validate: (value) => {
            if (required) {
              const parsedValue = value?.trim();
              if (!parsedValue) return "Por favor, preencha este campo.";
            }
          },
        })}
      />
      {errors?.[name] && (
        <span className="field-error-message">
          {(errors[name]?.message as any) || "Inválido"}
        </span>
      )}
    </>
  );
};
