import {
  CheckCircleFilled,
  CloseCircleFilled,
  FileDoneOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Flex,
  Row,
  Skeleton,
  Space,
  Statistic,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../services/Api";
import MediaQuery from "react-responsive";
import { getColorForUptime } from "../../utils/getColorForUptime";

const columns: TableProps<any>["columns"] = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, row) => (
      <>
        {text === "problem" && <Tag color="volcano">Problema</Tag>}
        {text === "resolved" && <Tag color="green">Resolvido</Tag>}
      </>
    ),
  },
  {
    title: "Monitoramento",
    dataIndex: "monitorName",
    key: "monitorName",
    render: (value, row) => (
      <>{!!value && <Link to={`/monitors/${row.monitorId}`}>{value}</Link>}</>
    ),
  },
  {
    title: "Iniciado em",
    dataIndex: "startedAt",
    key: "startedAt",
  },
  {
    title: "Duração",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Causa",
    dataIndex: "cause",
    key: "cause",
    render: (text) => (
      <Typography.Text type="secondary">Destino não respondeu</Typography.Text>
    ),
  },
  {
    title: "",
    key: "action",
    render: (text, row) => {
      return null;
    },
  },
];

function Dashboard() {
  const navigate = useNavigate();
  const { data: incidents, isLoading: isLoadingIncidents } = useQuery(
    `incidents`,
    async () => {
      const { data } = await Api.get(`/incidents`);
      return data.map((i: any) => {
        return {
          ...i,
          startedAt: new Date(i.createdAt).toLocaleString(),
          duration: "",
        };
      });
    }
  );

  const { data, isLoading } = useQuery(
    "me",
    async () => {
      const { data } = await Api.get("/me");

      const subscriptionStatus = data.allowedMonitorCount
        ? "active"
        : "inactive";

      return {
        ...data,
        subscriptionStatus,
      };
    },
    {
      refetchInterval: 1000,
    }
  );

  const { data: counts, isLoading: isLoadingCounts } = useQuery(
    "counts",
    async () => {
      const { data } = await Api.get("/counts");
      return data;
    }
  );

  const { mutate, isLoading: isLoadingStartSubscription } = useMutation({
    // @ts-ignore
    mutationFn: async () => {
      const response = await Api.post("/create-initial-checkout");
      window.location.href = response.data.url;
    },
  });

  useEffect(() => {
    if (data && !data.stripeCustomerId) {
      mutate();
    }
  }, [data, mutate]);

  const {
    mutate: goToPaymentDashboard,
    isLoading: isLoadingGoToPaymentDashboard,
  } = useMutation({
    // @ts-ignore
    mutationFn: async (data: any) => {
      const response = await Api.post("/create-payment-dashboard-session");
      window.location.href = response.data.url;
    },
  });

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Typography.Title level={3}>Monitoramentos</Typography.Title>
          <Row gutter={16} style={{ marginBottom: "1rem" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              style={{ marginBottom: "1rem" }}
            >
              <Link to={"/monitors"}>
                <Card bordered={false}>
                  <Skeleton loading={isLoadingCounts}>
                    <Statistic
                      title="Em operação"
                      value={counts?.upMonitors || 0}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={
                        <CheckCircleFilled style={{ marginRight: ".6rem" }} />
                      }
                    />
                  </Skeleton>
                </Card>
              </Link>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              style={{ marginBottom: "1rem" }}
            >
              <Link to={"/monitors"}>
                <Card bordered={false}>
                  <Skeleton loading={isLoadingCounts}>
                    <Statistic
                      title="Fora do ar"
                      value={counts?.downMonitors || 0}
                      valueStyle={{ color: "#cf1322" }}
                      prefix={
                        <CloseCircleFilled style={{ marginRight: ".6rem" }} />
                      }
                    />
                  </Skeleton>
                </Card>
              </Link>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              style={{ marginBottom: "1rem" }}
            >
              <Link to={"/contacts"}>
                <Card bordered={false}>
                  <Skeleton loading={isLoadingCounts}>
                    <Statistic title="Contatos" value={counts?.contacts || 0} />
                  </Skeleton>
                </Card>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Typography.Title level={3}>Status da conta</Typography.Title>
          <div
            className="secondary-card"
            style={{
              background: "#ffffff",
              borderRadius: 8,
            }}
          >
            <Skeleton loading={isLoading}>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Assinatura
              </Typography.Title>

              {data?.subscriptionStatus === "inactive" && (
                <>
                  <Typography.Paragraph>
                    Você ainda não possui uma assinatura ativa. Para iniciar uma
                    assinatura, por favor clique no botão abaixo.
                  </Typography.Paragraph>
                  <Button
                    type="primary"
                    onClick={() => mutate()}
                    loading={isLoadingStartSubscription}
                  >
                    Iniciar assinatura
                  </Button>
                </>
              )}

              {data?.subscriptionStatus === "active" && (
                <>
                  <Typography.Paragraph>
                    A sua assinatura está ativa, e o seu plano permite até{" "}
                    <strong>{data?.allowedMonitorCount || 0}</strong>{" "}
                    monitoramentos.
                  </Typography.Paragraph>
                  <Space>
                    <Dropdown.Button
                      menu={{
                        items: [
                          {
                            key: "invoices",
                            label: "Gerenciamento e faturas",
                            icon: <FileDoneOutlined />,
                            onClick: goToPaymentDashboard,
                          },
                        ],
                      }}
                      onClick={() => mutate()}
                      loading={
                        isLoadingGoToPaymentDashboard ||
                        isLoadingStartSubscription
                      }
                      type="primary"
                    >
                      Aumentar o plano
                    </Dropdown.Button>
                  </Space>
                </>
              )}
            </Skeleton>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Typography.Title level={3}>Últimos incidentes</Typography.Title>

          <div
            className="secondary-card"
            style={{
              background: "#ffffff",
              borderRadius: 8,
              overflow: "auto",
            }}
          >
            {!isLoadingIncidents && !incidents?.length && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Nenhum incidente registrado."
              />
            )}

            <MediaQuery maxWidth={600}>
              <Skeleton loading={isLoadingIncidents}>
                {incidents?.map((m: any) => (
                  <Card
                    style={{ marginBottom: "1rem" }}
                    onClick={() => {
                      navigate(`/monitors/${m.monitorId}`);
                    }}
                  >
                    <Flex justify="space-between" align="start">
                      <div>
                        <Typography.Text>{m.monitorName}</Typography.Text>
                        <br />
                        <Typography.Text type="secondary">
                          {m.startedAt}
                        </Typography.Text>
                      </div>
                      {m.resolvedAt ? (
                        <Tag color="green">Resolvido</Tag>
                      ) : (
                        <Tag color="red">Problema</Tag>
                      )}
                    </Flex>

                    {m.duration && (
                      <Typography.Text type="secondary">
                        Duração: {m.duration}
                      </Typography.Text>
                    )}

                    {m.last24HoursUptime && (
                      <Space size={2}>
                        {!!m.last24HoursUptime &&
                          Array.isArray(m.last24HoursUptime) &&
                          m.last24HoursUptime.map((item: any) => (
                            <div
                              key={item.startDate}
                              style={{
                                width: 8,
                                height: 24,
                                background: getColorForUptime(
                                  item.uptimePercentage
                                ),
                                borderRadius: 8,
                              }}
                            ></div>
                          ))}
                      </Space>
                    )}
                  </Card>
                ))}
              </Skeleton>
            </MediaQuery>

            <MediaQuery minWidth={601}>
              {(isLoadingIncidents || !!incidents?.length) && (
                <Table
                  columns={columns}
                  dataSource={incidents}
                  loading={isLoadingIncidents}
                />
              )}
            </MediaQuery>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
