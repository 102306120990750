import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddContact from "./pages/AddContact";
import AddForm from "./pages/AddForm";
import AnswerForm from "./pages/AnswerForm";
import Dashboard from "./pages/Dashboard";
import EditContact from "./pages/EditContact";
import EditMonitor from "./pages/EditMonitor";
import ListContacts from "./pages/ListContacts";
import ListForms from "./pages/ListForms";
import Loggedtemplate from "./pages/LoggedTemplate";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterForForms from "./pages/RegisterForForms";
import RegisterForFormsSuccess from "./pages/RegisterForFormsSuccess";
import ShowAnswer from "./pages/ShowAnswer";
import ShowForm from "./pages/ShowForm";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/f/:formId" Component={() => <AnswerForm />} />
          <Route path="/register" Component={() => <Register />} />
          <Route
            path="/register-for-forms"
            Component={() => <RegisterForForms />}
          />
          <Route
            path="/register-for-forms-success"
            Component={() => <RegisterForFormsSuccess />}
          />
          <Route path="/login" Component={() => <Login />} />
          <Route path="/dashboard" element={<Loggedtemplate />}>
            <Route path="" Component={() => <Dashboard />} />
          </Route>
          <Route path="/forms" element={<Loggedtemplate />}>
            <Route path="" Component={() => <ListForms />} />
            <Route path="new" Component={() => <AddForm />} />
            <Route path=":id/edit" Component={() => <EditMonitor />} />
            <Route path=":id" Component={() => <ShowForm />} />
            <Route
              path=":id/answer/:answerId"
              Component={() => <ShowAnswer />}
            />
          </Route>
          <Route path="/contacts" element={<Loggedtemplate />}>
            <Route path="" Component={() => <ListContacts />} />
            <Route path=":id/edit" Component={() => <EditContact />} />
            <Route path="new" Component={() => <AddContact />} />
          </Route>
          {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
