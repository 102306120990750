import { Breadcrumb, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { MonitorForm } from "../../components/MonitorForm";

function AddForm() {
  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="../">Formulários</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Configurar novo formulário</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          minHeight: 280,
          padding: 24,
          borderRadius: 8,
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <MonitorForm />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddForm;
